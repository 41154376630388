import React from 'react'
import corn from '../images/corn.jpg'
import corn01 from '../images/corn01.png'
import cornpack003 from '../images/cornpack003.jpg'
import corntable from '../images/corntable.jpg'

function Corn() {
  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Corn </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
          The corn plant is a tall annual grass with a stout, erect, solid stem. The large narrow leaves have wavy margins and are spaced alternately on opposite sides of the stem. Staminate (male) flowers are borne on the tassel terminating the main axis of the stem. corn is one of the world’s most productive and dominant crops. It is grown extensively as food for both humans and livestock, as a biofuel, and as a crude material in industry.
          </p>

          <p style={{ color: "black", marginTop: "10px" }}>
            To preserve the optimum flavor and sweetness of our corn, refrigerate the ears promptly upon acquisition. For extended freshness, if you plan to store them for a few days, husk the ears, immerse them in ice water for a brief rejuvenation, and then securely wrap and place them in the coldest section of your refrigerator.
          </p>
        </div>
      </div>

      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={corn01}
                    className="img-fluid"
                    alt="corn01"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={corn}
                    className="img-fluid"
                    alt="corn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavapacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={cornpack003}
                      className="img-fluid"
                      alt="cornpack003"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Corn
                    <img
                      src={corntable}
                      className="img-fluid"
                      alt="corntable"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Corn