import React, { useEffect, useState } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import './Nav.css';
import logo from './somfieldlogo.png';
import Text from './somfieldtext.png';
import ScrollReveal from 'scrollreveal';

function Nav({ isLoggedIn }) {
  const [activeLink, setActiveLink] = useState(null);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useEffect(() => {
    ScrollReveal().reveal('.animate', {
      duration: 1000,
      origin: 'bottom',
      distance: '20px',
      easing: 'ease-in-out',
      reset: true,
    });
  }, []);

  const handleLinkClick = (index) => {
    setActiveLink(index);
    setIsMenuOpen(false);
    setIsSubMenuOpen(false);
  };

  const handleLogout = () => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookieParts = cookies[i].split("=");
      const cookieName = cookieParts[0];
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    localStorage.clear();
    sessionStorage.removeItem("fullname");
    sessionStorage.removeItem("Login_Success");
    navigate('/');

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(prevState => !prevState);
  };

  return (
    <div className='mainfield'>
      <nav>
        <div className='logbtn'>
          <div className='mainlogo'>
            <img src={logo} alt="Logo" className="logo" />
            <img src={Text} alt="Text" className="text" />
          </div>
          <label htmlFor="check" className="checkbtn" onClick={toggleMenu}>
            <i className="fas fa-bars" />
          </label>
          <div>
            <ul className={isMenuOpen ? 'nav-links responsive' : 'nav-links'}>
              {!isLoggedIn ? (
                <>
                  <li>
                    <Link to='/' className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(0)}>
                      Home
                    </Link>
                  </li>
                  <li className="topmenu">
                    <Link onClick={toggleSubMenu}>
                      Product
                    </Link>
                    <ul className={`submenu ${isSubMenuOpen ? 'submenu-toggle' : ''}`}>
                      <li><Link to="/fruits" className={activeLink === 2 ? 'active' : ''} onClick={() => handleLinkClick(2)}>Fruit</Link></li>
                      <li><Link to="/vegetables" className={activeLink === 3 ? 'active' : ''} onClick={() => handleLinkClick(3)}>Vegetables</Link></li>
                      <li><Link to="/raisins" className={activeLink === 4 ? 'active' : ''} onClick={() => handleLinkClick(4)}>Dry Fruits</Link></li>
                      <li><Link to="/indianspices" className={activeLink === 5 ? 'active' : ''} onClick={() => handleLinkClick(5)}>Indian Spices</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about" className={activeLink === 6 ? 'active' : ''} onClick={() => handleLinkClick(6)}>About</Link>
                  </li>
                  <li>
                    <Link to="/contact" className={activeLink === 7 ? 'active' : ''} onClick={() => handleLinkClick(7)}>Contact</Link>
                  </li>
                  <li>
                    <Link to="/feedback" className={activeLink === 8 ? 'active' : ''} onClick={() => handleLinkClick(8)}>Feedback</Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/admin/contactview" className={activeLink === 9 ? 'active' : ''} onClick={() => handleLinkClick(9)}>Contact View</Link>
                  </li>
                  <li>
                    <Link to="/admin/feedbackview" className={activeLink === 10 ? 'active' : ''} onClick={() => handleLinkClick(10)}>Feedback View</Link>
                  </li>
                  <li onClick={handleLogout} className='log-out'>Logout</li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <section className="animated fadeInUp">
        <Outlet />
      </section>
    </div>
  );
}

export default Nav;
