import React from 'react'
import './IndianSpices.css'
import IndianSpicess from '../images/Indian Spices.png'
import IndianSpiceslogo from '../images/Indian Spiceslogo.png'
// import raision02 from '../images/raisin2.jpg'


function IndianSpices() {
    return (
        <>
            <div className='spicesparat'>
                <h1 className='animated fadeInLeft'> IndianSpices </h1>
                <div className='spices animated fadeInRight'>
                    <p style={{ color: "black", marginTop: "10px" }}>In the current set-up, the anti-proliferative, anti-hypercholesterolemic, anti-diabetic, anti-inflammatory effects of spices have overriding importance, as the key health concern of mankind nowadays is diabetes, cardio-vascular diseases, arthritis and cancer.
                        Spices are rich in antioxidants, and scientific studies suggest that they are also potent inhibitors of tissue damage and inflammation caused by high levels of blood sugar and circulating lipids.
                        Because spices have very low calorie content and are relatively inexpensive, they are reliable sources of antioxidants and other potential bioactive compounds in diet.
                    </p>
                </div>
            </div>

            <div className="guava-box">
                <div className="container">
                    <div className="row special-list">
                        <div className="col-lg-3 col-md-6 special-grid bulbs">
                            <div className="guava-single fix">
                                <div className="box-img-hover">
                                    <img
                                        src={IndianSpicess}
                                        className="img-spices"
                                        alt="IndianSpicess"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='spiceschart'>
                    <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Jan</th>
                                    <th>Feb</th>
                                    <th>Mar</th>
                                    <th>Apr</th>
                                    <th>May</th>
                                    <th>Jun</th>
                                    <th>Jul</th>
                                    <th>Aug</th>
                                    <th>Sep</th>
                                    <th>Oct</th>
                                    <th>Nov</th>
                                    <th>Dec</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>IndianSpices
                                        <img
                                            src={IndianSpiceslogo}
                                            className="img-fluid"
                                            alt="raisinstable"
                                        />
                                    </td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                    <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                    <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default IndianSpices