import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Nav from './components/Navigation/Nav';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Feedback from './components/Feedback/Feedback';
import Fruits from './components/Product/Fruits';
import IndianSpices from './components/Product/IndianSpices';
import Banana from './components/Product/Banana';
import Vegetables from './components/Product/Vegetables';
import DryFruit from './components/Product/DryFruit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "./components/Footer/Footer";
import Grapes from './components/Product/Grapes';
import Guava from './components/Product/Guava';
import Pomogranate from './components/Product/Pomogranate';
import Onion from './components/Product/Onion';
import Lemon from './components/Product/Lemon';
import Ladyfinger from './components/Product/Ladyfinger';
import Drumsticks from './components/Product/Drumsticks';
import Corn from './components/Product/Corn';
import Chilly from './components/Product/Chilly';
import Raisins from './components/Product/Raisins';
import Login from './components/Admin/Login/Login';
import ContactView from "./components/Admin/ContactView/ContactView";
import FeedbackView from "./components/Admin/FeedbackView/FeedbackView";

function AppContent({ isLoggedIn, setIsLoggedIn }) {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  useEffect(() => {
    if (sessionStorage.getItem("Login_Success") === "Done") {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  return (
    <>
      {(!isAdminRoute || (isAdminRoute && isLoggedIn)) && <Nav isLoggedIn={isLoggedIn} />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/fruits' element={<Fruits />} />
        <Route path='/vegetables' element={<Vegetables />} />
        <Route path='/dryfruit' element={<DryFruit />} />
        <Route path='/grapes' element={<Grapes />} />
        <Route path='/guava' element={<Guava />} />
        <Route path='/pomogranate' element={<Pomogranate />} />
        <Route path='/onion' element={<Onion />} />
        <Route path='/lemon' element={<Lemon />} />
        <Route path='/ladyfinger' element={<Ladyfinger />} />
        <Route path='/drumsticks' element={<Drumsticks />} />
        <Route path='/corn' element={<Corn />} />
        <Route path='/chilly' element={<Chilly />} />
        <Route path='/raisins' element={<Raisins />} />
        <Route path='/banana' element={<Banana />} />
        <Route path='/indianspices' element={<IndianSpices />} />
        <Route path='/admin' element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        {isLoggedIn && (
          <>
            <Route path='/admin/contactview' element={<ContactView />} />
            <Route path='/admin/feedbackview' element={<FeedbackView />} />
          </>
        )}
      </Routes>
      {!isAdminRoute && <Footer />}
    </>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <BrowserRouter>
      <AppContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </BrowserRouter>
  );
}

export default App;
