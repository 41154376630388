import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Typewriter from "./Typewriter";
import './Feedback.css'
import feedbackimg from '../images/somfieldlogo.png'
import feedbacktext from '../images/somfieldtext.png'

function Feedback() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch('http://localhost:4004/Somfield/api/v1/user/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        message: message,
      }),
    });

    const result = await response.json();
    setLoading(false);
    if (result.Response.Status.StatusCode === '0') {
      Swal.fire({
        title: 'Success',
        text: 'Thank You For Your Feedback!',
        icon: 'success',
      });
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Failed to send message',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row align-items-center">
                <div className="col-lg-7 mb-5 mb-lg-0 cover">
                  <div className="den">
                    <div className="cen">
                      <h1 className="mb-5 ">
                        <Typewriter text="THANKS !!! for Visiting Our Website" delay={150} />

                      </h1>
                      <form
                        className="border-right pr-5 mb-5 mform"
                        method="post"
                        id="contactForm"
                        name="contactForm"
                        onSubmit={handleSubmit}
                      >
                        <div className='ssform'>
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="fname"
                                id="fname"
                                placeholder="First name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="lname"
                                id="lname"
                                placeholder="Last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <textarea
                                className="form-control"
                                name="message"
                                id="message"
                                cols={30}
                                rows={7}
                                placeholder="Write your message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              {loading ? (
                                <div className="loader"></div>
                              ) : (
                                <input
                                  type="submit"
                                  value="Send Message"
                                  className="btn btn-primary rounded-0 py-2 px-4 "
                                  id='submitbutton'
                                />
                              )}
                              <span className="submitting" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ml-auto imgcontianer">
                          <img className="img-img" src={feedbackimg} alt="about-img" />
                          <img className="img-fluid" src={feedbacktext} alt="about-img" />
                        </div>
                      </form>
                      <div id="form-message-warning mt-4" />
                      <div id="form-message-success">
                        Your message was sent, thank you!
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>


  )
}

export default Feedback