import React, { useState, useEffect } from 'react';
import './Table.css';

const Table = ({ columns, data, setSearch, sortingEnabled, onSortChange, sort_by, order_by, itemsPerPage, itemsPerPageOptions = [10, 25, 50], setItemsPerPage, currentPage, setCurrentPage, totalItems }) => {
    const [currentItemsPerPage, setCurrentItemsPerPage] = useState(itemsPerPage);
    const [tempSearch, setTempSearch] = useState("");

    useEffect(() => {
        setCurrentItemsPerPage(itemsPerPage);
    }, [itemsPerPage]);

    const handleSortChange = (colKey) => {
        const newOrderBy = order_by === 'asc' ? 'desc' : 'asc';
        onSortChange(colKey, newOrderBy);
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = Number(e.target.value);
        setCurrentItemsPerPage(newItemsPerPage);
        setItemsPerPage(newItemsPerPage);
    };

    // Update the temporary search state as the user types
    const handleTempSearchChange = (e) => {
        setTempSearch(e.target.value);
    };

    // Update the actual search state when the user clicks the search button
    const handleSearch = () => {
        setSearch(tempSearch);
    };

    const totalPages = totalItems > 0 ? Math.ceil(totalItems / currentItemsPerPage) : 0;

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    return (
        <div>
            <div className='table-main'>
                <div className="search-container">
                    <input
                        type="text"
                        className="insearch"
                        placeholder="Search..."
                        value={tempSearch}
                        onChange={handleTempSearchChange}
                    />
                    <button className='searchbtn' onClick={handleSearch}>🔍</button>
                </div>

                <div className="tableFixHead">
                    <table className="atable">
                        <thead>
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        key={col.key}
                                        onClick={() => sortingEnabled && col.isSortable && handleSortChange(col.key)}
                                        style={{ cursor: sortingEnabled && col.isSortable ? 'pointer' : 'default' }}
                                    >
                                        <span>{col.label}</span>
                                        {sortingEnabled && sort_by === col.key && (
                                            <span>{order_by === 'asc' ? '⬆' : order_by === 'desc' ? '⬇' : ''}</span>
                                        )}
                                    </th>
                                ))}

                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((row, rowIndex) => (
                                    <tr key={row.id || rowIndex}>
                                        {columns.map((col) => (
                                            <td key={col.key}>{row[col.key]}</td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                // Display "No data available" if there are no records
                                <tr>
                                    <td colSpan={columns.length} style={{ textAlign: 'center' }}>No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="table-footer">
                    <div className="dropdown-container">
                        <label>Rows per page:</label>
                        <select value={currentItemsPerPage} onChange={handleItemsPerPageChange}>
                            {itemsPerPageOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>

                    <div className="pagination-buttons">
                        <div className="pagination-buttons">
                            <button onClick={handlePreviousPage} disabled={currentPage === 1 || totalItems === 0}>
                                Previous
                            </button>
                            <span>
                                Page {totalItems > 0 ? currentPage : 0} of {totalPages}
                            </span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages || totalItems === 0}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
