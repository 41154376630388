import React, { useState } from 'react';
import Swal from 'sweetalert2'
import './Contact.css'
// import { Link } from 'react-router-dom';


function Contact() {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch('http://localhost:4004/Somfield/api/v1/user/contact-us', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullname: fullname,
        email: email,
        message: message,
      }),
    });
    const result = await response.json();
    setLoading(false);
    if (result.Response.Status.StatusCode === '0') {
      Swal.fire({
        title: 'Success',
        text: 'We will contact you soon',
        icon: 'success'
      })
      setFullname('');
      setEmail('');
      setMessage('');
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Failed to send message',
        icon: 'error',
      });
    }
  };


  return (
    <div className='maincontact'>
      <div className="section-header">
        <div className="contactcontain">
          <h2>Contact Us</h2>
        </div>
      </div>
      <div className="contactcontain2">
        <h2>Contact Us</h2>
        <div className="contactrow">
          <div className="contact-info">
            <div className="contact-info-item">
              <div className="contact-info-icon">
                <i className="fas fa-home" />
              </div>
              <div className="contact-info-content">
                <h4>Address</h4>
                <p>
                  AT POST: MOHADI, TAL:DINDORI,
                  <br /> DIST: NASHIK, <br />
                  422207
                </p>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="contact-info-icon">
                <i className="fas fa-phone" />
              </div>
              <div className="contact-info-content">
                <h4>Phone</h4>
                <p>
                  +91 9503827422<br />
                  +91 7588833234
                </p>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="contact-info-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="contact-info-content">
                <h4>Email</h4>
                <p>info@somfield.com</p>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form id="contact-form" onSubmit={handleSubmit}>
              <h2>Send Message</h2>
              <div className="input-box">
                <input type="text" placeholder="Full Name" name="fullname" value={fullname} onChange={(e) => setFullname(e.target.value)}  />
              </div>
              <div className="input-box">
                <input type="email" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  />
              </div>
              <div className="input-box">
                <textarea  name="message" placeholder="Type your Message..." value={message} onChange={(e) => setMessage(e.target.value)} />
              </div>
              <div className="input-box">
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <input type="submit" value="Send" name="submit" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
