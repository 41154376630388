import React, { useState, useEffect } from 'react'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import videoSource from '../images/homevideo.mp4';
import grapes from '../images/grapes04.jpg'
import chillys from '../images/chillys.jpg'
import banana from '../images/banana.jpeg'
import onions from '../images/onion05.jpg'
import drumsticks from '../images/drumsticks01.jpg'
import ladyfingers from '../images/ladyfingers.jpg'
import pomogranate02 from '../images/pomogranate02.jpg'
import guava02 from '../images/guava04.jpg'
import lemon from '../images/lemonhome.jpg'
import corn from '../images/corn.jpg'
import dryfruits from '../images/dryfruits.jpg'
import freshfruits from '../images/freshfruits.png'
import ourvegetables from '../images/Fresh Vegetable.png'
import dryfruit from '../images/dryfruits1.png'
import indianspices from '../images/Indian Spices.png'
import './Home.css'

function Home() {

  const [activeButton, setActiveButton] = useState('fruits');

  const [display1, setDisplay_fru] = useState('block');
  const [display2, setDisplay_veg] = useState('none');
  const [display3, setDisplay_dry] = useState('none');
  const [display4, setDisplay_indianspices] = useState('none');

  const fruits_filter = () => {
    setDisplay_fru('block')
    setDisplay_veg('none');
    setDisplay_dry('none')
    setDisplay_indianspices('none')
  };

  const vegetables_filter = () => {
    setDisplay_fru('none')
    setDisplay_veg('block');
    setDisplay_dry('none')
    setDisplay_indianspices('none')
  };

  const dry_fruits_filter = () => {
    setDisplay_fru('none')
    setDisplay_veg('none');
    setDisplay_dry('block')
    setDisplay_indianspices('none')
  };

  const indian_spices_filter = () => {
    setDisplay_fru('none')
    setDisplay_veg('none');
    setDisplay_dry('none')
    setDisplay_indianspices('block')
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className='maincontain'>
        <MDBCarousel className="carsouelm mimgs" dark fade>
          <MDBCarouselItem className="mimg" itemId={1}>
            <div className="video-container">
              <video className="video-home video-element" autoPlay loop muted>
                <source src={videoSource} type="video/mp4" />
              </video>
            </div>
            <div className="carousel-caption d-md-block">
              {/* <div className={`fade-in-right ${scrollPosition > 100 ? 'visible' : ''}`}> */}
              <div className='second'>
                <h1>Welcome To <span> Somfield Export </span></h1>
                <p style={{ fontSize: "17px" }}>Pure From Fields To World.</p>
              </div>
            </div>
          </MDBCarouselItem>
        </MDBCarousel>

        <div className="homehead">
          <p>Featured Products <br /> <span>Our</span> <span style={{ color: "black" }} >Products</span></p>
        </div>
        <div className='Homeproduct'>
          <div className="first-pro">
            <div className="hometext">
              <p>Fruits</p>
            </div>
            <img
              src={freshfruits}
              className="img-home"
              alt="freshfruits"
            />
          </div>

          <div className="Second-pro">
            <div className="hometext">
              <p>Vegetables</p>
            </div>
            <img
              src={ourvegetables}
              className="img-home"
              alt="ourvegetables"
            />
          </div>

          <div className="Third-pro">
            <div className="hometext">
              <p>Dry Fruits</p>
            </div>
            <img
              src={dryfruit}
              className="img-home"
              alt="dryfruit"
            />
          </div>

          <div className="Four-pro">
            <div className="hometext">
              <p>Indian Spices</p>
            </div>
            <img
              src={indianspices}
              className="img-home"
              alt="indianspices"
            />
          </div>
        </div>
        <div className="products-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="special-menu text-center">
                  <div className="button-group filter-button-group">
                    <button
                      className={activeButton === 'fruits' ? 'active' : ''}
                      data-filter="*"
                      onClick={() => {
                        fruits_filter();
                        setActiveButton('fruits');
                      }}
                    >
                      Fruits
                    </button>

                    <button
                      className={activeButton === 'vegetables' ? 'active' : ''}
                      data-filter="top-featured"
                      onClick={() => {
                        vegetables_filter();
                        setActiveButton('vegetables');
                      }}
                    >
                      Vegetables
                    </button>

                    <button
                      className={activeButton === 'dry_fruits' ? 'active' : ''}
                      data-filter="best-seller"
                      onClick={() => {
                        dry_fruits_filter();
                        setActiveButton('dry_fruits');
                      }}
                    >
                      Dry fruits
                    </button>

                    <button
                      className={activeButton === 'indian_spices' ? 'active' : ''}
                      data-filter="best-seller"
                      onClick={() => {
                        indian_spices_filter();
                        setActiveButton('indian_spices');
                      }}
                    >
                      Indian Spices
                    </button>

                  </div>
                </div>
              </div>
            </div>



            <div className={`fade-in-left ${scrollPosition > 100 ? 'visible' : ''}`} style={{ display: display1 }}>
              <div className="row special-list">
                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={grapes}
                      className="img-fluid"
                      alt="grapes"
                    />
                    <div className="why-text">
                      <h3>Grapes</h3>
                      <p>
                        Boost immune system.
                        Prevents cancer.
                        Lowers blood pressure.
                        Protects against heart disease.
                        Reduces high cholesterol.
                        Slows down ageing.
                      </p>
                    </div>
                  </div>
                </div>



                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={pomogranate02}
                      className="img-fluid"
                      alt="pomogranate"
                    />
                    <div className="why-text">
                      <h3 >Pomogranate</h3>
                      <p>
                        Rich in nutrients.
                        Rich in antioxidants.
                        Prevents cancer.
                        Boost heart health.
                        Support urinary health.
                        Antimicromial properties.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={guava02}
                      className="img-fluid"
                      alt="guava"
                    />
                    <div className="why-text">
                      <h3>Guava</h3>
                      <p> Help lower blood sugar level.
                        Boost heart health.
                        Prevents cancer.
                        May help relieve painful symptoms on menstruation cycle,
                        Beneficial for digestive system
                        .</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={banana}
                      className="img-fluid"
                      alt="banana"
                    />
                    <div className="why-text">
                      <h3>Banana</h3>
                      <p> Improves blood sugar level.
                        Support digestive health.
                        Helps in weight loss.
                        Rich in antioxidants.
                        Help to maintain kidney health.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: display2 }}   >
              <div className="row special-list">
                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={lemon}
                      className="img-fluid"
                      alt="lemon"
                    />
                    <div className="why-text">
                      <h3> Lemon</h3>
                      <p>Weight loss.
                        Prevents kidney stone.
                        Protects anemia.
                        Improves digestive health.
                        Improves heart health.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={ladyfingers}
                      className="img-fluid"
                      alt="ladyfingers"
                    />
                    <div className="why-text">
                      <h3>Ladyfingers</h3>
                      <p>Ladyfinger is low in calories and a good source of dietary fiber.
                        It contains minerals, including vitamin C, potassium, and folate.</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={chillys}
                      className="img-fluid"
                      alt="chillys"
                    />
                    <div className="why-text">
                      <h3>Chillys</h3>
                      <p> Protects chronic disease.
                        Prevents diabetes.
                        Antimicrobial activity.
                        Weights loss.
                        Boost immune health.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={corn}
                      className="img-fluid"
                      alt="corn"
                    />
                    <div className="why-text">
                      <h3>Corn</h3>
                      <p>Improves digestion.
                        Rich in antioxidants.
                        Rich in nutrients.
                        Lowers diabetes.
                        Improves heart health.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={drumsticks}
                      className="img-fluid"
                      alt="drumsticks"
                    />
                    <div className="why-text">
                      <h3>Drumsticks</h3>
                      <p>Supports immune system. Anti inflammatory properties. Improves digestion.Improves heart health. Weight loss.</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={onions}
                      className="img-fluid"
                      alt="onions"
                    />
                    <div className="why-text">
                      <h3>Onion</h3>
                      <p>Onions are a fundamental ingredient in a wide range of cuisines worldwide.
                        They can  used raw in salads, salsas, and sandwiches or cooked in soups.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div style={{ display: display3 }}>
              <div className="row special-list">
                <div className="indian col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix">
                    <img
                      src={dryfruits}
                      className="img-fluid"
                      alt="dryfruits"
                    />
                    <div className="why-text">
                      <h3>DryFruits</h3>
                      <p>Raisin(Dry Fruit) made by just sun drying naturally grown grapes. Growing grapes naturally requires lot of experience and knowledge on natural farming.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: display4 }}>
              <div className="row special-list">
                <div className="indian col-lg-3 col-md-6 special-grid best-seller">
                  <div className="products-single fix spicecard">
                    <img
                      src={indianspices}
                      className="img-fluid"
                      alt="indianspices"
                      width="100%"
                    />
                    <div className="why-text">
                      <h3>Indian spices</h3>
                      <p>In the current set-up, the anti-proliferative, anti-hypercholesterolemic, as the key health concern of mankind nowadays is diabetes, cardio-vascular diseases, arthritis and cancer.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Home