import React, { useState, useEffect, useCallback } from 'react'
import './ContactView.css'
import Table from '../../Table/Table'

function ContactView() {
  const [dataRows, setDataRows] = useState([]);
  const [search, setSearch] = useState('');
  const [sortByValue, setSortByValue] = useState('');
  const [orderByValue, setOrderByValue] = useState('asc');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const formatData = useCallback((apiData) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return apiData.map((item, index) => ({
      contact_id: item.id,
      serialno: startIndex + index + 1,
      fullname: item.fullname,
      email: item.email,
      message: item.message,
      created_at_date: item.created_at_date,
      created_at_time: item.created_at_time,
    }));
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('x-auth-token');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }


        const response = await fetch(`http://localhost:4004/Somfield/api/v1/user/get-contact-us?search[text]=${encodeURIComponent(search)}&sort_by=${encodeURIComponent(sortByValue)}&order_by=${encodeURIComponent(orderByValue)}&offset=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'x-auth-token': token,
          },
        })

        if (!response.ok) {
          throw new Error('Failed to fetch machine data');
        }
        const responseData = await response.json();

        if (!responseData.Response.ResponseData.list) {
          throw new Error('Invalid or empty response data');
        }

        const list = responseData.Response.ResponseData.list;
        const formattedData = formatData(list);
        setDataRows(formattedData);

        const total = responseData.Response.ResponseData.count;
        setTotalItems(total);


      } catch (error) {
        console.error('Error fetching machine data:', error);
      }
    };

    fetchData();
  }, [search, sortByValue, orderByValue, itemsPerPage, currentPage, formatData]);

  const handleSortChange = (newSortBy) => {
    // If the currently sorted column is clicked again, update the order or reset.
    if (sortByValue === newSortBy) {
      if (orderByValue === '') {
        setOrderByValue('asc');
      } else if (orderByValue === 'asc') {
        setOrderByValue('desc');
      } else {
        // Reset to no sort
        setOrderByValue('');
        setSortByValue('');
      }
    } else {
      // If a new column is clicked, start sorting with 'asc'
      setSortByValue(newSortBy);
      setOrderByValue('asc');
    }
  };

  const handleSearch = (newSearchTerm) => {
    setSearch(newSearchTerm); // Update the search term
    setCurrentPage(1); // Reset pagination to the first page
  };

  const columns = [
    { key: 'serialno', label: 'Sr No.' },
    { key: 'fullname', label: 'Full Name', isSortable: true },
    { key: 'email', label: 'Email Id', isSortable: true },
    { key: 'message', label: 'Message', isSortable: true },
    { key: 'created_at_date', label: 'Created Date', isSortable: true },
    { key: 'created_at_time', label: 'Created Time', isSortable: true },
  ];

  return (
    <div className='contact-contain'>
      <h2 className='contactview-name'>Contact Details</h2>
      <Table
        columns={columns}
        data={dataRows}
        itemsPerPage={itemsPerPage}
        itemsPerPageOptions={[10, 25, 50]}
        sortingEnabled={true}
        setSearch={handleSearch}
        order_by={orderByValue}
        sort_by={sortByValue}
        setItemsPerPage={setItemsPerPage}
        onSortChange={handleSortChange}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
      />
    </div>

  )
}

export default ContactView