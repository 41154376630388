import React from 'react'
import ladyfinger from '../images/ladyfinger-main1.jpg'
import ladyfinger01 from '../images/lady-finger01.jpg'
import ladyfingerpack01 from '../images/lady-finger-pack1.jpg'
import ladyfingerpack02 from '../images/lady-finger-pack2.jpg'
import leadytable from '../images/leadytable.jpg'

function Ladyfinger() {
  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Ladyfinger </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
            Introducing our Bhindi, also known as okra or lady's finger – may benefit pregnant women, heart health, and blood sugar control. Our Bhindi is 100% certified, ensuring it is grown without the use of synthetic pesticides, herbicides, or fertilizers. This guarantees that you can relish the delicious taste of this vegetable without any concerns about harmful chemicals.
          </p>
          <p style={{ color: "black", marginTop: "10px" }}>
            An exceptional addition to any meal, Bhindi not only enhances the flavor but also brings a nutritional boost to your diet. Order now to experience the freshness and purity of Bhindi straight from our farm in Nashik.
          </p>
        </div>
      </div>

      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={ladyfinger01}
                    className="img-fluid"
                    alt="ladyfinger01"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={ladyfinger}
                    className="img-fluid"
                    alt="ladyfinger"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavapacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={ladyfingerpack01}
                      className="img-fluid"
                      alt="ladyfingerpack01"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={ladyfingerpack02}
                      className="img-fluid"
                      alt="ladyfingerpack02"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>LadyFinger
                    <img
                      src={leadytable}
                      className="img-fluid"
                      alt="leadytable"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Ladyfinger