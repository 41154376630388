import React from 'react'
import onionpack06 from '../images/onionpack006.jpg'
import onionpack05 from '../images/Onionpack05.png'
import onion05 from '../images/onion05.jpg'
import onion02 from '../images/onionpack02.png'
import oniontable from '../images/oniontable.png'

function Onion() {
  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Onion </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
            Maharashtra state rank first in overall onion production in India. India is the second largest onion growing country in the world.  Indian onions are popular for their pungency. They are available through the year, it has got two crop cycles, first harvesting starts in November to January and the second harvesting from January to May.
          </p>
        </div>
      </div>

      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={onion05}
                    className="img-fluid"
                    alt="onion05"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={onion02}
                    className="img-fluid"
                    alt="onion02"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavapacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={onionpack05}
                      className="img-fluid"
                      alt="onionpack"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={onionpack06}
                      className="img-fluid"
                      alt="onionpack01"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Onion
                    <img
                      src={oniontable}
                      className="img-fluid"
                      alt="oniontable"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Onion