import React from 'react'
import './fruits.css'
import pomo from '../images/pomo04.jpg'
import guava from '../images/gauva.png'
import grapes from '../images/grapes.jpg'
import banana from '../images/banana01.png'
import { useNavigate } from 'react-router-dom';

function Fruits() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigate = useNavigate();

  const handleNavigateTograpes = () => {
    navigate('/grapes');
  };

  const handleNavigateToguava = () => {
    navigate('/guava');
  };

  const handleNavigateTopomo = () => {
    navigate('/pomogranate');
  };

  const handleNavigateTobanana = () => {
    navigate('/banana');
  };
  return (
    <>
      <div className="fruit_section layout_padding-top">
        <div className="container">
          <h2 className="custom_heading animated fadeInLeft mt-4" >Fresh Fruits</h2>

          <div className='fruits animated fadeInRight'>
            <p>
            Certainly! Nashik, located in Maharashtra, India, is not only famous for its vegetable cultivation but also renowned for its diverse fruit production. The region's semi-arid climate, coupled with fertile soil and suitable growing conditions, makes it ideal for cultivating a wide range of fruits. Here are some notable fruits grown in Nashik: Grapes, Pomegranates, Bananas, Guavas, etc.
            </p>

            <p>Nashik's fruit cultivation complements its vegetable farming, contributing significantly to the region's agricultural economy. The availability of a variety of fruits from Nashik not only meets local demand but also contributes to the export market, showcasing the diversity and richness of Maharashtra's agricultural landscape.</p>
          </div>

          <div className="row layout_padding2 animated fadeInRight fruitcontent">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Pomogranate</h3>
                <p className="mt-4 ">
                  Rich in nutrients. Rich in antioxidants. Prevents cancer.
                </p>
                <p className=" mb-5 ">
                  Boost heart health. Support urinary health. Antimicromial properties.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateTopomo(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="fruit_img-box ">
                <img src={pomo} alt="pomo" className="" width="350px" />
              </div>
            </div>
          </div>

          <div className="row layout_padding2 animated fadeInLeft fruitcontent">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Grapes</h3>
                <p className="mt-4 ">
                  Boost immune system. Prevents cancer. Lowers blood pressure. Protects against heart disease.
                </p>
                <p className=" mb-5 ">
                  Reduces high cholesterol. Slows down ageing.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateTograpes(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="fruit_img-box d-flex justify-content-center  ">
                <img src={grapes} alt="grapes" className="grapes" width="240px"  />
              </div>
            </div>
          </div>

          <div className="row layout_padding2-top fruitcontent animated fadeInRight layout_padding-bottom" >
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Gauva</h3>
                <p className="mt-4 ">
                  Help lower blood sugar level. Boost heart health. Prevents cancer.
                </p>
                <p className=" mb-5 ">
                  May help relieve painful symptoms on menstruation cycle, Beneficial for digestive system .
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateToguava(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="fruit_img-box ">
                <img src={guava} alt="guava" className="guava" width="300px" />
              </div>
            </div>
          </div>

          <div className="row layout_padding2 animated fadeInLeft fruitcontent">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Banana</h3>
                <p className="mt-4 ">
                  Improves blood sugar level. Support digestive health. Helps in weight loss. Rich in antioxidants.
                </p>
                <p className=" mb-5 ">
                  Help to maintain kidney health.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateTobanana(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="fruit_img-box d-flex justify-content-center ">
                <img src={banana} alt="banana" className="banana" width="300px" />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <div className='furitss'>
        <div className='fruitcontent'>
          <h1 className='animated fadeInLeft'>FRESH FRUITS</h1>
          <div className='animated fadeInRight'>
            <p>
              "We are situated in Nashik, a city nestled in the fertile lands of Maharashtra, India, renowned for its prominence in agriculture and horticulture. Blessed with a favorable combination of rich soil, ideal weather conditions, and abundant freshwater sources, Nashik has become a hub for cultivating produce of exceptional quality, taste, and nutritional value. Our region specializes in the cultivation of premium Grapes, Pomegranates, Guavas, and an array of other fresh fruits and vegetables."
            </p>

            <p>The strategic proximity of Nashik to the nearby Sea and Air ports in Mumbai enables us to seamlessly deliver our products to various continents while preserving their freshness, vibrant colors, and natural flavors. This geographical advantage allows us to provide top-notch produce to global markets, ensuring a delightful and wholesome experience for consumers worldwide.</p>
          </div>
        </div>

        <div className='animated fadeInUp'>
          <div className="product-type">
            <div className="container">
              <div className="row ">
                <div className="col-lg-3 col-md-6 special-grid bulbs">
                  <div className="type-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack01}
                        className="img-fluid"
                        alt="grapespack01"
                      />
                      <h5>Grapes</h5>
                      <button  onClick={() =>{handleNavigateTograpes();scrollToTop();}}>Read More</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 special-grid bulbs">
                  <div className="type-single fix">
                    <div className="box-img-hover">
                      <img
                        src={guava02}
                        className="img-fluid"
                        alt="guava02"
                      />
                      <h5>Guava</h5>
                      <button onClick={() => handleNavigateToguava()}>Read More</button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 special-grid bulbs">
                  <div className="type-single fix">
                    <div className="box-img-hover">
                      <img
                        src={pomo}
                        className="img-fluid"
                        alt="pomo"
                      />
                      <h5>Pomogranate</h5>
                      <button onClick={() => handleNavigateTopomo()}>Read More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Fruits