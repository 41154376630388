import React from 'react'
import './Grapes.css'
import grapespack01 from '../images/grapespack01.jpg'
import grapespack02 from '../images/grapespack02.jpg'
import grapespack03 from '../images/grapespack03.jpg'
import grapespack06 from '../images/grapespack06.jpg'
import grapespack07 from '../images/grapespack07.jpg'
import tabgrap01 from '../images/tabgrap01.jpg'
import tabgrap02 from '../images/tabgrap02.jpg'
import grapespack05 from '../images/grapespack05.jpg'
import tabgrap04 from '../images/tabgrap04.jpg'
import crimson from '../images/tabcrimson.png'
import jumbo from '../images/tabjumbo.png'
import sonaka from '../images/tabsonaka.png'
import grapesmain05 from '../images/grapesmain5.jpg'
import grapesmain06 from '../images/grapespack011.jpg'
function Grapes() {
  return (
    <>
      <div className='grapes_container'>
        <div className='grapescontent'>
          <h1 className='animated fadeInLeft'>Grapes</h1>
          <div className='animated fadeInRight'>

            <p>
              Nashik district stands as the foremost grape-producing region in India, benefitting from its optimal climatic conditions that foster the cultivation of grapes renowned for their exceptional sweetness, nutritional richness, freshness, vibrant colors, and impressive sizes. Our involvement in the export of fresh grapes dates back to 1993, and our presence in this domain has steadily expanded over the years.
            </p>

            <p>
              Sweet or lightly sour, white or black, grapes are usually welcomed as a sudden burst of natural sugars that explode in one's mouth. Beyond the taste, grapes or 'vitis vinifera' are natural antioxidants and a great source for resveratrol which protects people from environmental stress; and flavonoids with anti-aging and anti-inflammatory properties.
            </p>
          </div>
        </div>

        <div className='animated fadeInUp'>
          <div className="grapes-box">
            <div className="container">
              <div className="row special-list">
                <div className="col-lg-3 col-md-6 special-grid bulbs">
                  <div className="grapes-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack01}
                        className="img-fluid"
                        alt="grapespack01"
                      />
                      <h5>Thomson Seedless</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 special-grid fruits">
                  <div className="grapes-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack02}
                        className="img-fluid"
                        alt="grapespack02"
                      />
                      <h5>Sharad Seedless</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 special-grid bulbs">
                  <div className="grapes-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack03}
                        className="img-fluid"
                        alt="grapespack03"
                      />
                      <h5>Flame Seedless</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 special-grid bulbs">
                  <div className="grapes-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack05}
                        className="img-fluid"
                        alt="grapespack05"
                      />
                      <h5>Jumbo Seedless</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 special-grid fruits">
                  <div className="grapes-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack06}
                        className="img-fluid"
                        alt="grapespack06"
                      />
                      <h5>Crimson Seedless</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 special-grid podded-vegetables">
                  <div className="grapes-single fix">
                    <div className="box-img-hover">
                      <img
                        src={grapespack07}
                        className="img-fluid"
                        alt="grapespack07"
                      />
                      <h5>Sonaka Seedless</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='grapespacking'>
              <h1 className='animated fadeInLeft'> PACKING </h1>
              <div className="container">
                <div className="row special-list">
                  <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                    <div className="grapes-single fix">
                      <div className="box-img-hover">
                        <img
                          src={grapesmain05}
                          className="img-fluid"
                          alt="grapesmain05"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                    <div className="grapes-single fix">
                      <div className="box-img-hover">
                        <img
                          src={grapesmain06}
                          className="img-fluid"
                          alt="grapesmain05"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='grapchart grapes-box'>
          <h1 className='animated fadeInLeft sea'> SEASONAL CHART </h1>

          <div className="grapes-table" style={{ marginBottom: '30px' }}>
            <table className='grapes_main'>
              <thead>
                <tr>
                  <th className="sticky-cell">Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sticky-cell">Thompson / White Seedless
                    <img
                      src={tabgrap01}
                      className="img-fluid"
                      alt="tabgrap01"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>

                <tr>
                  <td className="sticky-cell">Sharad / Black Seedless
                    <img
                      src={tabgrap02}
                      className="img-fluid"
                      alt="tabgrap02"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>

                <tr>
                  <td className="sticky-cell">Flame Seedless
                    <img
                      src={tabgrap04}
                      className="img-fluid"
                      alt="tabgrap04"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>

                <tr>
                  <td className="sticky-cell">Jumbo Seedless
                    <img
                      src={jumbo}
                      className="img-fluid"
                      alt="jumbo"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>

                <tr>
                  <td className="sticky-cell">Crimson Seedless
                    <img
                      src={crimson}
                      className="img-fluid"
                      alt="crimson"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>

                <tr>
                  <td className="sticky-cell"> Sonaka Seedless
                    <img
                      src={sonaka}
                      className="img-fluid"
                      alt="sonaka"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Grapes 