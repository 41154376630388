import React from 'react'
import './DryFruit.css'
import dryfruit from '../images/dryfruits.jpg'
// import raisinstable from '../images/raisinstable.jpg'
import { useNavigate } from 'react-router-dom';

function DryFruit() {
  const navigate = useNavigate();

  const handleNavigateTodryfruits = () => {
    navigate('/Raisins');
  };

  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Dryfruits </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
            Indulge in the exquisite flavors of Nashik's renowned grape orchards – the heart of India's grape country. Our premium raisins promise to elevate the taste of your sweet creations or bring a delightful sweetness to your savory dishes. Carefully harvested from green grape vines, these raisins boast a natural charm, having avoided exposure to harmful pest repellents and processed through eco-friendly drying techniques. The commitment to a cultivation process ensures the wholesomeness and safety of these raisins.
          </p>

          <p style={{ color: "black", marginTop: "10px" }}>
            despite their petite size, these raisins pack a punch of energy and are rich in fiber, vitamins, and minerals. By choosing these raisins, you not only savor exceptional flavor and enhance your well-being, but you also support farmers in their pursuit of agriculture. Opting for a fruit that is traditionally laden with pesticides, these raisins contribute to a farming approach that prioritizes health, sustainability, and the environment. Enjoy the goodness of nature with every bite, knowing that your choice extends beyond taste to fostering a commitment to healthier farming practices.
          </p>
        </div>
      </div>



      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={dryfruit}
                    className="img-fluid"
                    alt="dryfruit"
                  />
                  <h5>Dryfruit</h5>
                  <button onClick={() => handleNavigateTodryfruits()}>Read More</button>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                            <div className="guava-single fix">
                                <div className="box-img-hover">
                                    <img
                                        src={raision02}
                                        className="img-fluid"
                                        alt="raision02"
                                    />
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>


        {/* <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dryfruits
                    <img
                      src={raisinstable}
                      className="img-fluid"
                      alt="raisinstable"
                    />
                  </td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i class="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i class="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i class="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>


      </>
  )
}

export default DryFruit