import React from 'react'
import './Guava.css'
import guava04 from '../images/guava04.jpg'
import guava02 from '../images/guava02.jpg'
import guavapack01 from '../images/guava_pack.jpg'
import guavapack02 from '../images/guavapack02.jpg'
import guavatable from '../images/guavatable.jpg'


function Guava() {
  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Guava </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
          Guava is a common tropical fruit cultivated in many tropical and subtropical region. Both the guava fruit and leaves provide antioxidants, fiber, vitamins, minerals, and antimicrobial properties that boost immunity, regulate blood sugar, improve digestion and heart health, encourage weight loss, and more. Adding guava to your diet or using guava leaf extracts can help you look and feel your best.
          </p>
        </div>
      </div>

      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={guava04}
                    className="img-fluid"
                    alt="guava04"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={guava02}
                    className="img-fluid"
                    alt="guava02"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavapacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={guavapack01}
                      className="img-fluid"
                      alt="guavapack01"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={guavapack02}
                      className="img-fluid"
                      alt="guavapack02"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Guava
                    <img
                      src={guavatable}
                      className="img-fluid"
                      alt="guavatable"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Guava