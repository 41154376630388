import './About.css'
import aboutimg from '../images/about-img.png'
import slideimage from '../images/commit.png'
import aboutplate from '../images/Experties.jpg'
import vision from '../images/vision.jpg'
import React, { useState, useEffect } from 'react'


function About() {

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="about-box-main">
        <div className="container">

          <div className="row som-about">
            <div className="col-lg-6">
              <div >
                <img className="img-fluid" src={aboutimg} alt="about-img" />
              </div>
            </div>
            <div className="col-lg-6  headline">
              <h2 className="noo-sh-title-top fresh ">
                We are <span>Somfield</span>
              </h2>
              <p className='dis'>
                SOMFIELD EXPORT is the export industry from the city rich in fruits and vegetables Nashik India from the idea of Gaurav Somvanshi who is well educated and belong to the agricultural background.Somfield started in 2023 aims to make a ecosystem which will provide purest and safe quality food to the consumer and good value for thier products to the farmer.

              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6  headline1" >
              <div className={`fade-in-left ${scrollPosition > 100 ? 'visible' : ''}`}  >
                <h2 className="noo-sh-title-top fresh " style={{ marginTop: '50px' }}>
                  Our <span>Committment</span>
                </h2>
                <p className='dis'>
                  Our commitment to quality and safety standards is unwavering, ensuring that agricultural
                  exports meet stringent quality benchmarks while prioritizing consumer health and wellbeing. We envision an industry that promotes transparency, traceability, and ethical
                  sourcing, earning trust and recognition globally.
                </p>
              </div>
            </div>

            <div className="col-lg-6 mb-5 mt-5">
              <div className={`fade-in-right ${scrollPosition > 100 ? 'visible' : ''}`}  >
                <img className="img-fluid" style={{ borderRadius: "40px" }} src={slideimage} alt="about-img" />
              </div>
            </div>
          </div>

          <div className="row som-vision">
            <div className="col-lg-6">
              <div className={`fade-in-left ${scrollPosition > 100 ? 'visible' : ''}`} >
                <img style={{ borderRadius: "40px" }} className="img-fluid" src={vision} alt="about-img" />
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <div className={`fade-in-right ${scrollPosition > 100 ? 'visible' : ''}`}  >
                <h2 className="noo-sh-title-top fresh ">
                  Our <span>Vision</span>
                </h2>
                <p className='dis'>
                  Our vision for agricultural export is to provide the fresh products from the fields of india to the globe. We aspire to build a future where our export practices will provide pure quality food to the consumers and good value to the farmers for their produce.Our vision includes empowering farmers with knowledge, tools, and resources to adopt sustainable farming practices that preserve natural resources, enchance thier productions, and mitigate climate change effects.Through strategic partnerships and collaborations, we aim to foster resilient agricultural ecosystems that ensure food sovereignty and create livelihood opportunities for rural communities.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6  headline1">
              <div className={`fade-in-left ${scrollPosition > 100 ? 'visible' : ''}`}  >
                <h2 className="noo-sh-title-top fresh " style={{ marginTop: '50px' }}>
                  Our <span>Experties</span>
                </h2>
                <p className='dis'>
                  We work to improve the efficiency and productivity of agricultural systems and to develop new and better ways of managing resources .we are expertise  in providing fresh and pure quality food product from the fields of India to the consumer. We  ensure that our consumer gets best quality, and our farmer gets worth for his efforts and hard work.
                </p>
              </div>
            </div>

            <div className="col-lg-6 mb-5 mt-5">
              <div className={`fade-in-right ${scrollPosition > 100 ? 'visible' : ''}`} >
                <img style={{ borderRadius: "40px" }} className="img-fluid" src={aboutplate} alt="about-img" />
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default About