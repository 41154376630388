import { useNavigate } from 'react-router-dom';
import './Vegetables.css'
import React from 'react'
import ladyfinger from '../images/lady-finger01.png'
import lemon001 from '../images/lemon001.png'
import chilly from '../images/chilly.png'
import corn from '../images/corn.png'
import onionveg from '../images/onionvege1.png'
import drumst from '../images/drumst.png'


function Vegetables() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleNavigateToladyfinger = () => {
    navigate('/ladyfinger');
  };

  const handleNavigateTolemon = () => {
    navigate('/lemon');
  };

  const handleNavigateToChilly = () => {
    navigate('/chilly');
  };

  const handleNavigateToCorn = () => {
    navigate('/corn');
  };

  const handleNavigateToOnion = () => {
    navigate('/onion');
  };

  const handleNavigateToDrumstick = () => {
    navigate('/drumsticks');
  };
  return (
    <>

      <div className="fruit_section layout_padding-top">
        <div className="container">
          <h2 className="custom_heading  mt-4 animated fadeInLeft" >Vegetables</h2>
          <div className='fruits animated fadeInRight'>
            <p>
              Nashik, located in the state of Maharashtra, India, is known for its vibrant agriculture sector, including the cultivation of various vegetables.Nashik experiences a semi-arid climate, making it suitable for the cultivation of a variety of crops, including vegetables.
              The region has a monsoon season from June to September, followed by a dry period.
            </p>

            <p>Nashik is particularly famous for vegetable cultivation. The region is a significant producer of vegetable, and the crop is a major contributor to the local economy. Nashik is a major hub for onion production in India, and a significant portion of the country's onion exports originates from this region.
              Indian vegetable from Nashik are often exported to various countries, meeting the demand in the international market.
              Vegetable from Nashik are known for their pungency and are preferred in certain global markets.</p>
          </div>

          <div className="row layout_padding2 fruitcontent animated fadeInRight">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Ladyfinger</h3>
                <p className="mt-4 ">
                  Weight loss. Prevents kidney stone. Protects anemia.
                </p>
                <p className=" mb-5 ">
                  Improves digestive health. Improves heart health.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateToladyfinger(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="fruit_img-box">
                <img src={ladyfinger} alt="ladyfinger" className="veg_img" width="350px" />
              </div>
            </div>
          </div>


          <div className="row layout_padding2 fruitcontent animated fadeInLeft">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Lemon</h3>
                <p className="mt-4 ">
                  Lemons, known for their astringent flavor, are used in pastries, desserts, and savory dishes worldwide.
                </p>
                <p className=" mb-5 ">
                  Rich in vitamin C and citric acid, they are key ingredients in lemonade and tea.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateTolemon(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="fruit_img-box ">
                <img src={lemon001} alt="lemon001" className="veg_img" width="350px" />
              </div>
            </div>
          </div>


          <div className="row layout_padding2 fruitcontent animated fadeInRight">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Chilly</h3>
                <p className="mt-4 ">
                  Protects chronic disease. Prevents diabetes.
                </p>
                <p className=" mb-5 ">
                  Antimicrobial activity. Weights loss. Boost immune health.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateToChilly(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="fruit_img-box">
                <img src={chilly} alt="chilly" className="veg_img" width="350px" />
              </div>
            </div>
          </div>

          <div className="row layout_padding2 fruitcontent animated fadeInLeft">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Corn</h3>
                <p className="mt-4 ">
                  Improves digestion. Rich in antioxidants. Rich in nutrients. Lowers diabetes.
                </p>
                <p className=" mb-5 ">
                  Improves heart health.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateToCorn(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="fruit_img-box">
                <img src={corn} alt="corn" className="veg_img" width="400px" />
              </div>
            </div>
          </div>


          <div className="row layout_padding2 fruitcontent animated fadeInRight">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Onion</h3>
                <p className="mt-4 ">
                  Onions are a fundamental ingredient in a wide range of cuisines worldwide.
                </p>
                <p className=" mb-5 ">
                  They can used raw in salads, salsas, and sandwiches or cooked in soups and more.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateToOnion(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="fruit_img-box">
                <img src={onionveg} alt="onionveg" className="veg_img" width="350px" />
              </div>
            </div>
          </div>

          <div className="row layout_padding2 fruitcontent animated fadeInLeft">
            <div className="roww">
              <div className="fruit_detail-box">
                <h3>Drumsticks</h3>
                <p className="mt-4 ">
                  Supports immune system. Anti inflammatory properties.
                </p>
                <p className=" mb-5 ">
                  Improves digestion.Improves heart health. Weight loss.
                </p>
                <div className='button'>
                  <button className="custom_dark-btn" onClick={() => { handleNavigateToDrumstick(); scrollToTop(); }}>
                    See Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="fruit_img-box">
                <img src={drumst} alt="drumst" className="veg_img" width="350px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Vegetables