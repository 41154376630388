import React from 'react'
import lemongreen from '../images/lemongreen.png'
import lemon01 from '../images/lemon01.png'
import lemonpack03 from '../images/lemonpack003.jpg'
import lemontable from '../images/lemontable.jpg'
import './Lemon.css'

function Lemon() {
  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Lemon </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
          Limon, small tree or spreading bush of the rue family (Rutaceae) and its edible fruit. Lemon juice is a characteristic ingredient in many pastries and desserts, such as tarts and the traditional American lemon meringue pie. The distinctive astringent flavor of the fruit, either fresh or preserved, is also used to enhance many poultry, fish, and vegetable dishes worldwide. Lemonade, made with lemon, sugar, and water, is a popular warm-weather beverage, and the juice itself is commonly added to tea. Citric acid may amount to 5 percent or more by weight of the lemon’s juice, which is also rich in vitamin C and contains smaller amounts of the B vitamins, particularly thiamin, riboflavin, and niacin.
          </p>
          <p style={{ color: "black", marginTop: "10px" }}>
            Rich in Vitamin C, our lemons contribute to a robust immune system and boast antioxidants that combat free radicals in the body. The natural acidity of lemon juice serves as a potent preservative, making it a flavorful enhancement to a myriad of dishes.
          </p>
        </div>
      </div>

      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={lemon01}
                    className="img-fluid"
                    alt="lemon01"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={lemongreen}
                    className="img-fluid"
                    alt="lemongreen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavapacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={lemonpack03}
                      className="img-fluid leomncard"
                      alt="lemonpack03"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lemon
                    <img
                      src={lemontable}
                      className="img-fluid"
                      alt="lemontable"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Lemon