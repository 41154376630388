import React from 'react'
import chilly01 from '../images/chilly01.jpeg'
import chillytable from '../images/chillytable.jpg'
import chillypack from '../images/chillypack.jpg'
function Chilly() {
  return (
    <>
      <div className='guavaparat'>
        <h1 className='animated fadeInLeft'> Chilly </h1>
        <div className='animated fadeInRight'>
          <p style={{ color: "black", marginTop: "10px" }}>
            Indian cooking is incomplete without a generous use of green chilies. The green vegetable adds the hot, spicy taste that is typical to Indian cuisine. Capsaicin, the chemical responsible for its shiny green color and spiciness is also responsible for several other health benefits.
          </p>
        </div>
      </div>

      <div className="guava-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid bulbs">
              <div className="guava-single fix">
                <div className="box-img-hover">
                  <img
                    src={chilly01}
                    className="img-fluid"
                    alt="chilly01"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavapacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="guava-single fix">
                  <div className="box-img-hover">
                    <img
                      src={chillypack}
                      className="img-fluid"
                      alt="chillypack"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='guavachart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Chilly
                    <img
                      src={chillytable}
                      className="img-fluid"
                      alt="chillytable"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Chilly