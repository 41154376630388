import React from 'react'
import './Raisins.css'
import raision from '../images/raision.png'
import raisinstable from '../images/raisinstable.jpg'
import raision02 from '../images/raisin2.jpg'

function Raisins() {
    return (
        <>
            <div className='Raisins_container'>
                <div className='raisinspart'>
                    <h1 className='animated fadeInLeft'> Raisins </h1>
                    <div className='raisins animated fadeInRight'>
                        <p style={{ color: "black", marginTop: "10px" }}>
                            Delight in the essence of Indian Raisins, crafted predominantly from succulent seedless grapes transformed through a meticulous drying process. These petite treasures boast a sweet, delectable flavor enveloped in a charmingly wrinkled texture. Each grape undergoes a carefully curated journey of natural dehydration, preserving its nutritional integrity and culminating in the creation of premium quality raisins. Experience the essence of nature's sweetness, encapsulated in every bite.
                        </p>
                    </div>
                </div>

                <div className="guava-box">
                    <div className="container">
                        <div className="row special-list">
                            <div className="col-lg-3 col-md-6 special-grid bulbs">
                                <div className="guava-single fix">
                                    <div className="box-img-hover">
                                        <img
                                            src={raision}
                                            className="img-fluid"
                                            alt="raision"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                                <div className="guava-single fix">
                                    <div className="box-img-hover">
                                        <img
                                            src={raision02}
                                            className="img-fluid"
                                            alt="raision02"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='raisinschart'>
                        <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

                        <div className="table-container" style={{ overflowX: "auto" }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Jan</th>
                                        <th>Feb</th>
                                        <th>Mar</th>
                                        <th>Apr</th>
                                        <th>May</th>
                                        <th>Jun</th>
                                        <th>Jul</th>
                                        <th>Aug</th>
                                        <th>Sep</th>
                                        <th>Oct</th>
                                        <th>Nov</th>
                                        <th>Dec</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-label="Product">Raisins
                                            <img
                                                src={raisinstable}
                                                className="img-raisins"
                                                alt="raisinstable"
                                            />
                                        </td>
                                        <td data-label="Jan"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Feb"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Mar"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Apr"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="May"><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                        <td data-label="Jun"><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                        <td data-label="Jul"><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                        <td data-label="Aug"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Sep"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Oct"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Nov"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                        <td data-label="Dec"><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Raisins
