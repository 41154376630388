import React from 'react'
import './Banana.css'
import bananapack from '../images/bannapacks.jpg'
import banana001 from '../images/banana001.jpeg'
import banana0001 from '../images/banana002.png'
import bananachart from '../images/bananachart.jpeg'
function Banana() {
    return (
        <>
            <div className='guavaparat'>
                <h1 className='animated fadeInLeft'> Banana </h1>
                <div className='animated fadeInRight'>
                    <p style={{ color: "black", marginTop: "10px" }}>
                        Bananas are an important and economical fruit crop of many tropical and subtropical regions of India, and stem from a herbaceous plant known as 'Musa'. Bananas are an excellent source of vitamin B6, soluble fiber, and contain moderate amounts of vitamin C, manganese and potassium. Consumption of bananas is known to reduce risk of colorectal cancer, breast cancer and renal cell carcinoma. It is also known to reduce heartburn, stroke, and help counter stress and insomnia.
                    </p>
                </div>
            </div>

            <div className="guava-box">
                <div className="container">
                    <div className="row special-list">
                        <div className="col-lg-3 col-md-6 special-grid bulbs">
                            <div className="banana-single fix">
                                <div className="box-img-hover">
                                    <img
                                        src={banana001}
                                        className="img-fluid"
                                        alt="banana001"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                            <div className="banana-single fix">
                                <div className="box-img-hover">
                                    <img
                                        src={banana0001}
                                        className="img-fluid"
                                        alt="banana0001"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='guavapacking'>
                    <h1 className='animated fadeInLeft'> PACKING </h1>
                    <div className="container">
                        <div className="row special-list">
                            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                                <div className="guava-single fix">
                                    <div className="box-img-hover">
                                        <img
                                            src={bananapack}
                                            className="img-fluid"
                                            alt="bananapack"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='guavachart'>
                    <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Jan</th>
                                    <th>Feb</th>
                                    <th>Mar</th>
                                    <th>Apr</th>
                                    <th>May</th>
                                    <th>Jun</th>
                                    <th>Jul</th>
                                    <th>Aug</th>
                                    <th>Sep</th>
                                    <th>Oct</th>
                                    <th>Nov</th>
                                    <th>Dec</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Banana
                                        <img
                                            src={bananachart}
                                            className="img-fluid"
                                            alt="bananachart"
                                        />
                                    </td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                    <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                    <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                    <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Banana