import React from 'react'
import './Pomogranate.css'
import pomo01 from '../images/pomoho.jpeg'
import pomo02 from '../images/pomo025.jpg'
import Pomogranatepack01 from '../images/pomopackage.jpg'
import Pomogranatepack02 from '../images/pomegranatepack02.png'
import pomoicon from '../images/pomoicon.png'

function Pomogranate() {
  return (
    <>

      <div className='pomopara'>
        <h1 className='animated fadeInLeft'> Pomegranate </h1>
        <div className='animated fadeInRight'>
          <p>Pomegranate, (Punica granatum), bush or small tree of the family Lythraceae and its fruit. The juicy arils of the fruit are eaten fresh, and the juice is the source of grenadine syrup, used in flavorings and liqueurs. Pomegranate is high in dietary fiber, folic acid, vitamin C, and vitamin K.</p>
          <p>At Somfield Export, we take pride in providing a continuous supply of fresh pomegranates throughout the year, thanks to our extensive network of dedicated associate farmers. Our commitment to quality and freshness ensures a bittersweet taste experience that distinguishes our produce.</p>
          <p>For more information and exclusive offers, we invite you to connect with us. Additionally, beyond whole pomegranate fruits, we offer Fresh Arils and IQF Arils with customizable packaging options, catering to a diverse range of preferences. Elevate your pomegranate experience with Somfield Export – where freshness and quality converge.</p>
        </div>
      </div>

      <div className="pomo-box">
        <div className="container">
          <div className="row special-list">
            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="pomo-single fix">
                <div className="box-img-hover">
                  <img
                    src={pomo01}
                    className="img-fluid"
                    alt="pomo01"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
              <div className="pomo-single fix">
                <div className="box-img-hover">
                  <img
                    src={pomo02}
                    className="img-fluid"
                    alt="pomo02"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pomopacking'>
          <h1 className='animated fadeInLeft'> PACKING </h1>
          <div className="container">
            <div className="row special-list ">
              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="pomo-single fix">
                  <div className="box-img-hover">
                    <img
                      src={Pomogranatepack01}
                      className="img-fluid"
                      alt="Pomogranatepack01"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 special-grid root-and-tuberous">
                <div className="pomo-single fix">
                  <div className="box-img-hover">
                    <img
                      src={Pomogranatepack02}
                      className="img-fluid"
                      alt="Pomogranatepack02"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pomochart'>
          <h1 className='animated fadeInLeft'> SEASONAL CHART </h1>

          <div className="table-container" style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pomegranate
                    <img
                      src={pomoicon}
                      className="img-fluid pomoicon"
                      alt="pomoicon"
                      width="40px"
                    />
                  </td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-xmark fa-beat" style={{ color: "red" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                  <td><i className="fa-solid fa-check fa-beat" style={{ color: "green" }}></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Pomogranate