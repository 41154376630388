import React, { useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import unimesh from "./images/unimeshfinal.png"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Button({ type, value }) {
    return (
        <button type={type} className="my-button">
            {value}
        </button>
    );
}


function Input({ type, id, name, label, placeholder, autofocus, onChange }) {
    return (
        <label className="my-label">{label}
            <input
                autoFocus={autofocus}
                type={type}
                id={id}
                name={name}
                placeholder={placeholder}
                className="my-input"
                onChange={onChange}
            />
        </label>
    );
}

function LoginForm() {
    const [credential, setCredential] = useState('');
    const [password, setPassword] = useState('');
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const val = {
            credential,
            password
        }
    
        try {
            const response = await fetch('http://localhost:4004/Somfield/api/v1/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(val),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                MySwal.fire({
                    title: <strong>Invalid response</strong>,
                    text: errorText,
                    icon: 'error',
                });
                return;
            }
    
            const data = await response.json();
    
            if (data.Response.Status.StatusCode === "0") {
                localStorage.setItem('x-auth-token', data.Response.ResponseData.x_auth_token);
                sessionStorage.setItem('fullname', data.Response.ResponseData.fullname);
                sessionStorage.setItem("Login_Success", "Done");
                navigate('/admin/contactview', { replace: true });
                window.history.pushState(null, null, window.location.pathname);
                window.location.reload();
            } else {
                handleErrorResponse(data.Response.Status.ErrorCode);
            }
        } catch (err) {
            console.error('Fetch error:', err);
            MySwal.fire({
                title: <strong>Network error</strong>,
                text: err.message,
                icon: 'error',
            });
        }
    }
    
    const handleErrorResponse = (errorCode) => {
        let message = '';
        switch (errorCode) {
            case "004":
                message = "Password or login credential is missing.";
                break;
            case "008":
                message = "Email id doesn't match.";
                break;
            case "014":
                message = "You entered incorrect password.";
                break;
            default:
                message = "An unknown error occurred.";
        }
        MySwal.fire({
            title: <strong>{message}</strong>,
            icon: 'warning',
        });
    }
    

    return (
        <div className="my-container">
            <div className="my-box">
                <h2 className='my-title'>Somfield Export</h2>
                {/* <h3 className='my-sub-title'>System</h3> */}
                <h1 className="my-heading">Log In</h1>
                <form className='my-form' onSubmit={handleSubmit}>
                    <Input type="text" id="credential" name="credential" label="Email Address / User" placeholder="email address / user" onChange={(e) => setCredential(e.target.value)} autofocus={true} />
                    <Input type="password" id="password" name="password" label="Password" placeholder="••••••••••" onChange={(e) => setPassword(e.target.value)} />
                    <Button type="submit" value="Submit" />
                    <div className="unim">
                        <div className="uni">
                            <img src={unimesh} alt="unimeshlogo" />
                            {/* <div className="uniname">
                                <h2>UniMesh</h2>
                                <h5>Technologies</h5>
                            </div> */}
                        </div>
                        <p>Connecting Future!</p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginForm;
