import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import somfieldlogo from '../images/somfieldlogo.png'
import somfieldtextlogo from '../images/somfieldtext.png'
import './Footer.css'
import { Link } from 'react-router-dom';
function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <div className="position-relative">
                <div className="footercontaner">
                    <MDBContainer className='text-center text-black text-md-start mt-5 bg-image shadow-1-strong rounded position-absolute top-0 start-0 end-0 bottom-0'>
                        <MDBRow className='mt-3 '>
                            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase text-black fw-bold mb-4'>
                                    <img  className='footer_logo' src={somfieldlogo} alt='somfieldlogo' />
                                    <img className='footer_text' src={somfieldtextlogo} alt='somfieldlogo' />
                                </h6>
                                <div className='copyright'>© Somfield 2024. All rights reserved.</div>
                            </MDBCol>
                            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                <h6 className='text-uppercase text-black fw-bold mb-4 mainmenu'>Main Menu</h6>
                                <p><Link onClick={scrollToTop} to='/' className='mainmenu-sub'>Home</Link></p>
                                <p>
                                    <Link onClick={scrollToTop} to='/fruits' className='mainmenu-sub'>
                                        Product
                                    </Link>
                                </p>
                                <p>
                                    <Link onClick={scrollToTop} to='/about' className='mainmenu-sub'>
                                        About us
                                    </Link>
                                </p>
                                <p>
                                    <Link onClick={scrollToTop} to='/contact' className='mainmenu-sub'>
                                        Contact us
                                    </Link>
                                </p>
                                <p>
                                    <Link onClick={scrollToTop} to='/feedback' className='mainmenu-sub'>
                                        Feedback
                                    </Link>
                                </p>

                            </MDBCol>
                            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase text-black fw-bold mb-4 mainmenu'>Contact</h6>
                                <p className='text-dark fw-bold d-flex'>
                                    <MDBIcon icon="home" className="me-2 text-black" />
                                    <span className='mainmenu-sub' style={{ marginTop: "-7px" }}>AT POST: MOHADI, TAL:DINDORI, DIST: NASHIK, PINCODE:422207</span>
                                </p>
                                <p className='mainmenu-sub'>
                                    <MDBIcon icon="envelope" className="me-3" />
                                   <a href="mailto:info@somfield.com" style={{color:"#3b71ca", cursor:"pointer"}}>info@somfield.com</a> 
                                </p>
                                <p className='mainmenu-sub'>
                                <MDBIcon icon="phone" className="me-3" /> +91 9503827422  <br></br>
                                    <MDBIcon icon="phone" className="me-3" /> +91 7588833234  
                                    
                                 
                                </p>
                            </MDBCol>
                        </MDBRow>
                        <div className='mb-4 d-flex justify-content-center' >
                            <MDBBtn
                                floating
                                className='m-1'
                                style={{ backgroundColor: '#3b5998' }}
                                to='#!'
                                role='button'
                            >
                                <MDBIcon fab icon='facebook-f' />
                            </MDBBtn>

                            <MDBBtn
                                floating
                                className='m-1'
                                style={{ backgroundColor: '#55acee' }}
                                to=''
                                role='button'
                            >
                                <MDBIcon fab icon='twitter' />
                            </MDBBtn>

                            <MDBBtn
                                floating
                                className='m-1'
                                style={{ backgroundColor: '#dd4b39' }}
                                to=''
                                role='button'
                            >
                                <MDBIcon fab icon='google' />
                            </MDBBtn>
                            <MDBBtn
                                floating
                                className='m-1'
                                style={{ backgroundColor: '#ac2bac' }}
                                to=''
                                role='button'
                            >
                                <MDBIcon fab icon='instagram' />
                            </MDBBtn>
                        </div>
                    </MDBContainer>
                </div>
            </div>
        </>
    );
}

export default Footer;
